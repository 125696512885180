.step-container
  &__expansion-panel
    .MuiExpansionPanelDetails-root
      display: flex
      flex-direction: column

    &__info,
    &__questions
      margin-bottom: 30px

    &__questions
      display: flex
      flex-direction: column

    &__actions
      border: 1px dotted black
      display: flex
      flex-direction: row
      align-items: center
      padding: 5px
      padding-left: 10px
      margin-right: 5px

      &__text
        display: block
        margin-right: 5px

    &__summary
      .MuiAccordionSummary-content
        align-items: center

      &__category
        padding-left: 5px
        color: grey
