body,
#root {
  margin: 0;
  height: 100vh;
  width: 100vw;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
  background-color: #f0f0f0;
}

.spacer {
  display: flex;
  flex: 1;
}

.page {
  width: 80%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 500px) {
  .page {
    width: 95%;
  }
}

.breadcrumbs a {
  display: flex;
}

.breadcrumbs a svg {
  margin-right: 5px;
}

.breadcrumbs a.active {
  cursor: default;
  text-decoration: none;
}

.ck-editor__editable {
  min-height: 200px;
}

.mt {
  margin-top: 15px !important;
}

.mb {
  margin-bottom: 15px !important;
}
