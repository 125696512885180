.scan-result-page-container
  display: flex
  flex-direction: column

  @media print
    margin: 0
    width: 100%
    height: 100%
    overflow: hidden

  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 20px

    @media print
      display: none

    @media screen and ( min-width: 500px )
      margin-bottom: 20px

    @media screen and ( max-width: 500px )
      .breadcrumbs
        display: none

  &__content
    @media print
      padding: 0
      margin: 0

    @media screen and ( max-width: 500px )
      div.MuiGrid-item:first-child
        margin-bottom: 20px

  &__paper
    padding: 20px

    @media print
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      box-shadow: none !important
      border-radius: 0 !important

    @media screen and ( min-width: 959px )
      margin-bottom: 20px

    p
      margin-top: 0

    &.filters
      display: flex
      flex-direction: column
      height: calc(100% - 20px)

    &__title
      display: block
      font-size: 26px

    &__sub-title
      display: block
      font-size: 16px

    &__title-container
      display: flex
      align-items: center
      margin-bottom: 20px

      &__filter
        font-size: 18px
        margin-right: 10px

      &.wrapper
        justify-content: space-between

    &__radar
      height: 500px
      width: 100%
      margin-bottom: 20px

      @media print
        height: 100%
        margin-bottom: 0

  .graph-container
    @media print
      display: flex
      justify-content: center
      align-content: center
      width: 100%
      height: 100%

  .filters-container,
  .explanation-container
    @media print
      display: none

.filters__filter
  margin-bottom: 20px !important

.with-subtitles
  flex-direction: column
  align-items: start
