.scan-detail-page-container
  display: flex
  flex-direction: column
  justify-content: space-between
  position: relative
  overflow: auto

  @media screen and ( max-width: 500px )
    &__inner__header
      display: none

  &__inner__header,
  &__inner__content,
  &__stepper
    margin-top: 20px

  &__inner__content,
  &__stepper
    display: flex

  &__inner
    display: block
    width: 60%
    margin: 0 auto

    @media screen and ( max-width: 500px )
      width: 95%

    &__content
      flex: 1
      flex-direction: column

      &__information,
      &__paper
        margin-bottom: 20px

      &__information
        padding: 15px

      &__paper
        width: 100%
        padding: 15px

        h1
          margin-bottom: 0

        .subtitle
          font-size: 1.3em
          color: grey
          font-style: italic

  &__stepper
    padding-top: 20px
    padding-bottom: 20px
    position: sticky
    bottom: 0
    border-top: 1px solid rgba(0, 0, 0, 0.12)
    background-color: #f0f0f0

    &__inner
      width: 60%
      display: flex
      justify-content: space-between
      align-items: center
      margin: 0 auto

      &__active-category
        font-size: 18px

      &__progress
        display: flex
        padding: 0 15px
        width: 100%
        align-items: center
        justify-content: center
        flex-direction: column
        padding-bottom: 7px

        span
          margin-bottom: 5px

        &__bar
          width: 100%
