.questionnaire-details-container
  display: flex
  flex-direction: column
  padding: 15px

  &__title
    font-size: 22px

  &__title,
  &__form-control .MuiInputBase-formControl
    margin-bottom: 30px

  &__button-container
    display: flex
    justify-content: flex-end

  .ck-editor__editable
    margin-bottom: 30px

  &__checkbox
    margin-bottom: 30px

    label
      margin-left: 0
