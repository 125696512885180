.data-list-container
  &__header
    margin-top: 20px
    margin-bottom: 20px

  &__paper
    padding: 15px

    &__content
      margin-bottom: 20px

    &__actions
      display: flex
      justify-content: center

      button:nth-child(1)
        margin-right: 10px

      button:nth-child(2)
        margin-left: 10px
