.menu
  @media print
    display: none

.row
  display: flex
  flex-direction: row

.column
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center

  &:first-child
    margin-right: 15px

    @media screen and ( max-width: 500px )
      display: none

.inner-container
  margin: 10px 20px
  width: 400px

  .email
    font-size: 15px
    color: #E5007D

  .seperator
    margin: 0 10px

  ul li.MuiListItem-root,
  a
    cursor: pointer

  a
    text-decoration: none

.user-container,
.inner-container
  .button
    height: 30px

  .team
    margin-top: 5px
    color: black
    font-size: 12px

  .icon
    color: #000
    height: 100%
    width: 50px
    &__role-item
      cursor: pointer

.root
  display: block
  height: 100vh,
  box-sizing: border-box,
  overflow: auto,

.menu-container
  @media screen and ( min-width: 768px )
    width: 240px
    flex-shrink: 0

.header-container
  height: 65px
  display: flex
  justify-content: center
  font-weight: 500
  color: #E5007D
  background-color: #fff

  @media print
    display: none

.content-container
  height: calc(100% - 65px)
  display: flex
  flex-direction: column
  margin-top: 65px
  overflow: auto

  @media print
    margin-top: 0
    margin-left: 0
    width: 100%

.header-container,
.content-container
  @media screen and ( min-width: 768px )
    width: calc(100% - 240px)
    margin-left: 240px

.menu-button
  margin-right: 16px

  @media screen and ( min-width: 768px )
    display: none

.toolbar
  background-color: #fff

.title
  color: #E5007D

.drawer .MuiDrawer-paper
  width: 240px
