.item-list
  height: 100%
  display: flex
  flex-direction: column
  font-family: "Roboto"
  background-color: #663366
  color: white

  .spacer
    flex: 1

  .logo-container
    height: 150px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    .logo
      height: 50px
      width: 50px

  ul a
    padding: 20px !important

    span
      font-size: 13px !important

  &__icon
    color: white !important

  .active
    background-color: lighten(#663366, 10%)
    color: white

    span
      font-weight: 600

    &:hover,
    &:focus
      background-color: lighten(#663366, 10%)

hr.divider
  background-color: rgba(255, 255, 255, 0.6)

.privacy-statement
  text-align: center
  text-decoration: none
  font-size: 12px
  padding: 20px
  color: white
