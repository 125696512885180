.user-update-dialog-container
  .label
    display: block
    margin-top: 3px
    font-weight: 500

  .user-role
    display: flex
    flex-direction: row
    margin-bottom: 5px

  .divider
    margin: 0 7.5px

  .delete-button
    width: 50px

  .add-button
    margin-top: 3px
