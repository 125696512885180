.editable-question-container
  width: 100%
  position: relative
  margin-top: 15px
  padding-left: 16px
  padding-right: 16px
  padding-top: 1px
  padding-bottom: 1px
  border: 1px solid transparent

  &.active
    border: 1px solid rgba(0, 0, 0, 0.6)
    padding-left: 15px
    padding-right: 15px
    padding-top: 0
    padding-bottom: 0

  &__header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    margin-top: 15px
    margin-bottom: 15px

    span
      font-weight: bold

  .form-control
    margin-bottom: 15px

  &__required
    display: flex
    flex-direction: row
    justify-content: flex-end
    margin-bottom: 15px

  &__action
    display: none
    position: absolute
    z-index: 4
    top: 5px
    right: 5px

  &:hover:not(.active)
    border: 1px dotted rgba(0, 0, 0, 0.6)
    cursor: pointer
    .editable-question-container__action
      display: block
