.stepper
  display: flex
  padding-top: 20px
  padding-bottom: 20px
  position: sticky
  bottom: 0
  border-top: 1px solid rgba(0, 0, 0, 0.12)
  background-color: #f0f0f0
  z-index: 3

  &__inner
    width: 60%
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 auto

    @media screen and ( max-width: 500px  )
      width: 95%

      button span
        font-size: 11px

    &__progress
      display: flex
      padding: 0 15px
      width: 100%
      align-items: center
      justify-content: center
      flex-direction: column
      padding-bottom: 7px

      span.percentage
        margin-bottom: 5px

      &__bar
        width: 100%
