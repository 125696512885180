.question-container
  margin-bottom: 30px
  width: 100%

  &__header
    display: flex
    flex-direction: row
    margin-bottom: 10px

    &__title
      display: block
      margin-right: 7px
      font-size: 18px
