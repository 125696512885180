.questionnaire-list-item-container
  min-height: 200px
  display: flex
  flex-direction: column

  &__header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    &__title
      span
        font-size: 20px
        font-weight: 500

    &__actions
      display: flex
      justify-content: flex-end

      &__menu svg
        margin-right: 7px
