.error-container
  font-family: "Roboto"
  display: flex
  flex: 1
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%

  &__title,
  &__code,
  &__message
    font-weight: 300