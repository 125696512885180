.login-container
  width: 500px
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin: 0 auto

  @media screen and ( max-width: 500px  )
    width: 90%

  &__title
    font-size: 30px
    font-family: "Roboto"
    font-weight: 300
    margin-bottom: 30px
