.team-list-container
  &__header,
  &__table
    margin-top: 20px

  &__header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
