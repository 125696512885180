.likert-scale-type
  width: 100%

  .detailed
    min-width: fit-content
    position: relative
    border-collapse: collapse
    border-spacing: 0

  .compact
    .container
      margin-bottom: 20px

      .instruction
        margin-bottom: 10px

        span
          display: block
          font-size: 1rem
          font-weight: 400

  &__header,
  &__content
    word-break: break-word
    display: flex
    justify-content: center

  &__column
    position: relative
    display: flex
    align-items: center
    justify-content: center
    &__instruction-cell
      display: flex
      align-items: center
      min-width: 350px
      span
        word-break: break-word
        margin-right: 7px

  &__row
    display: grid

    &:first-child
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4)
      background-color: white
      position: sticky
      top: 0
      z-index: 2
      font-weight: normal
      div
        text-align: center

    > div
      padding: 5px

    &__grey
      background-color: #dddddd

    &__light-grey
      background-color: #eaeaea

  tr th:first-child, td:first-child
    max-width: 400px
    min-width: 180px
