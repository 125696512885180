.scan-list-container
  &__header,
  &__drafts,
  &__history
    margin-top: 30px

  &__header
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between

    @media screen and ( max-width: 500px  )
      justify-content: flex-end

      .breadcrumbs
        display: none

  &__drafts,
  &__history
    span
      font-size: 20px
      font-weight: 300

    &__table
      margin-top: 10px

    .history-row
      .actions
        width: 120px

    // Draft
    @media screen and ( max-width: 896px )
      .draft-row,
      .no-data-draft-row
        &.MuiTableRow-root
          display: grid

      .draft-row
        grid-template-columns: 0 auto 0 0 100px

        .MuiTableCell-root
          padding: 16px 0
          overflow: hidden
          height: 60px
          padding-left: 16px
          display: flex
          align-items: center

          &.text-fit
            display: block
            white-space: nowrap
            text-overflow: ellipsis
            padding-top: 20px

      .no-data-draft-row
        grid-template-columns: auto

    // History
    @media screen and ( max-width: 1140px )
      .history-row,
      .no-data-history-row
        &.MuiTableRow-root
          display: grid

      .history-row
        grid-template-columns: 0 auto 100px 0 0 0 100px

        .MuiTableCell-root
          padding: 16px 0
          overflow: hidden
          height: 60px
          padding-left: 16px
          display: flex
          align-items: center

          &.text-fit
            display: block
            white-space: nowrap
            text-overflow: ellipsis
            padding-top: 20px

      .no-data-history-row
        grid-template-columns: auto
