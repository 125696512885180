.roles-container
  display: flex
  flex-direction: column

  &__title-container
    display: flex
    padding: 15px

    .title
      font-size: 22px
      flex: 1

  .empty-row td
    text-align: center

  &__table
    width: 100%
