.select-input
  &__title
    margin-bottom: 15px

  &__options
    max-height: 200px
    overflow-y: auto
    padding-top: 5px

  &__option
    display: flex
    flex-direction: row
    margin-bottom: 15px

    &__form-control.MuiFormControl-root
      margin-right: 7px

  &__add-option
    margin-top: 15px
    margin-bottom: 15px

  &__default-value
    margin-bottom: 15px