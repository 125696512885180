.questionnaire-statistics-page-container
  margin-top: 20px

  @media print
    margin: 0
    width: 100%
    height: 100%
    overflow: hidden

    @page
      size: landscape

  .header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px

    @media print
      display: none

    .breadcrumbs
      flex: 1

    @media screen and ( max-width: 500px )
      .breadcrumbs,
      .print
        display: none

  .title-container
    display: flex
    align-items: center
    margin-bottom: 20px

    span
      display: block
      margin-right: 7px
      font-size: 18px

  .paper
    padding: 20px
    margin-bottom: 20px

    @media print
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      box-shadow: none !important
      border-radius: 0 !important

    p
      margin-top: 0

  .checkbox
    display: block

  .graph-container
    @media print
      display: flex
      justify-content: center
      align-content: center
      width: 100%
      height: 100%

    .inner
      width: 100%
      height: 500px
      margin-bottom: 20px

  .filter-container,
  .explanation-container
    @media print
      display: none