.questionnaire-list-container
  &__search,
  &__table
    margin-top: 30px

  &__search
    width: 100%
    position: sticky
    position: -webkit-sticky
    top: 80px
    z-index: 2
    display: flex
    flex-direction: row

    form
      flex: 1

    &__control
      width: calc(100% - 10px)
      margin-right: 10px
      background-color: white

  &__information
    margin-top: 20px
    display: flex
    flex-direction: row

    &__statistics
      display: flex
      flex: 1
      justify-content: flex-end

      span
        font-size: 16px
        font-weight: 300
