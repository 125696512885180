.likert-scale-input
  &__title
    margin-bottom: 15px

  &__columns,
  &__rows
    max-height: 200px
    overflow-y: auto
    padding-top: 5px

  &__column,
  &__row
    display: flex
    flex-direction: row
    margin-bottom: 15px

  &__form-control.MuiFormControl-root
    margin-right: 7px

  &__add-column,
  &__add-row
    margin-top: 15px
    margin-bottom: 15px