.register-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 0 auto
  height: 100%

  @media screen and ( max-width: 500px )
    width: 90%

  &__content
    height: 600px
    display: flex
    flex-direction: column

    .card
      display: flex
      flex: 1
      margin-bottom: 15px

      h1
        font-size: 28px
        margin-bottom: 15px

      form
        background-color: white

        .form-control
          width: 100%
          margin-bottom: 15px

    .button-group
      display: flex
      justify-content: space-between
      margin: 40px 0
      width: 100%
