.questionnaire-detail-page-container
  .breadcrumbs,
  &__details
    margin-top: 30px

  &__details
    display: flex
    flex-direction: column

    &__questionnaire
      padding: 15px

      &__form-control
        margin-top: 15px
        margin-bottom: 15px

      &__button-container
        display: flex
        justify-content: flex-end

    &__steps
      margin-top: 30px

    &__fab
      padding-top: 10px
      padding-bottom: 10px
      display: flex
      justify-content: flex-end