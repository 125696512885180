.home-page-container
  margin: 0 auto
  margin-top: 20px
  margin-bottom: 20px
  padding: 15px
  width: 700px
  height: 100%
  display: flex
  flex: 1
  flex-direction: column

  @media screen and ( max-width: 500px  )
    width: 95%
